<template>
  <div class="main">
    <!-- 导航 -->
    <!-- <div class="nav">
         <span :class="{'active':(active==1)}" @click="selectItem(1)">问答首页</span>
         <span :class="{'active':(active==2)}" @click="selectItem(2)">问题全部</span>
         <span :class="{'active':(active==3)}" @click="selectItem(3)">推荐专家</span>
       </div> -->
    <!-- 内容 -->
    <div>
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      //  active:1
    };
  },
  created() {
    // this.active=this.active
  },
  // watch: {
  //   '$route' (to, from) { //监听路由是否变化
  //       if(to.path!= from.path){
  //           if(to.path==='/qaIndex'){
  //               this.active=1;
  //               return;
  //           }
  //           if(to.path==='/allQuestion'){
  //               this.active=2;
  //               return;
  //           }
  //           if(to.path==='/recommended'){
  //               this.active=3;
  //               return;
  //           }
  //       }
  //   }
  // },
  methods: {
    // selectItem(index){
    //     this.active=index;
    //     if(this.active==1){
    //         this.$router.push("/qaIndex");
    //         return;
    //     }
    //     if(this.active==2){
    //         this.$router.push("/allQuestion");
    //         return;
    //     }
    //       if(this.active==3){
    //         this.$router.push("/recommended");
    //         return;
    //     }
    // }
  },
};
</script>

<style scoped>
/* .main .nav{
    text-align: center;
    height: 90px;
    line-height: 55px;
  }
  .main .nav span{
    display: inline-block;
    margin-left: 80px;
    margin-top: 15px;
    height: 30px;
    cursor: pointer;
  }
  .main .nav span:first-of-type{
    margin-left: 0px;
  }
      
  .main .nav .active{
    color: #42b983;
  } 
.nav span{
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: normal;
  color: #3D3D3D;
} */
</style>